import React, { useCallback, useEffect, useRef, useState } from "react";
import mugIcon from "../../assets/mug-map.png";
import carIcon from "../../assets/map_car.png";
import { useHistory } from "react-router-dom";
import { Button } from "../shared";
import styled from "styled-components";
import { toast } from "react-toastify";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

const UPDATE_VENUE = gql`
  mutation ($changes: VenueInput!, $venueId: ID!) {
    updateVenue(changes: $changes, venueId: $venueId) {
      venue {
        name
        status
        moreInfo
      }
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  height: 90%;
  height: calc(100% - 50px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1rem;
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
  margin: 0;
  display: flex;
  grid-gap: 0.25rem;
`;

// Default location (Denver, CO) if no locations are provided
const defaultLocation = { lat: 39.742043, lng: -104.991531 };
const defaultZoom = 12;
const HereMap = ({ venues = [], onCloseMap }) => {
  const [updateVenue] = useMutation(UPDATE_VENUE);
  const history = useHistory();
  const mapRef = useRef(null);
  const apiKey = "lnj8nVC4p63zLCI_T6vN9FepLvyZK4PtiQsKAGliG0U";
  const [map, setMap] = useState(null);
  const [mapIcon, setMapIcon] = useState(null);
  const [mapCar, setMapCar] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [markersGroup, setMarkersGroup] = useState(null);
  const [ui, setUI] = useState(false);
  const [isMapReady, setIsMapReady] = useState(false);

  // Load the HERE Maps scripts
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://js.api.here.com/v3/3.1/mapsjs-core.js`;
    script.async = true;
    document.head.appendChild(script);

    const scriptsToLoad = [
      "https://js.api.here.com/v3/3.1/mapsjs-service.js",
      "https://js.api.here.com/v3/3.1/mapsjs-mapevents.js",
      "https://js.api.here.com/v3/3.1/mapsjs-ui.js",
      "https://js.api.here.com/v3/3.1/mapsjs-ui.css",
      "https://js.api.here.com/v3/3.1/mapsjs-clustering.js"
    ];

    const loadScripts = () => {
      scriptsToLoad.forEach((url) => {
        if (url.endsWith(".css")) {
          const link = document.createElement("link");
          link.rel = "stylesheet";
          link.href = url;
          document.head.appendChild(link);
        } else {
          const script = document.createElement("script");
          script.src = url;
          script.async = true;
          document.head.appendChild(script);
        }
      });
    };

    script.onload = () => {
      loadScripts();
      setTimeout(initMap, 1000);
    };

    const initMap = () => {
      if (window.H && window.H.map && window.H.mapevents && window.H.ui) {
        // Initialize the platform object
        const herePlatform = new window.H.service.Platform({
          apikey: apiKey
        });

        // Get default map layers
        const defaultLayers = herePlatform.createDefaultLayers();

        // Initialize map
        const newMap = new window.H.Map(mapRef.current, defaultLayers.vector.normal.map, {
          center: defaultLocation,
          zoom: defaultZoom,
          pixelRatio: window.devicePixelRatio || 1
        });

        // Make the map interactive
        const behavior = new window.H.mapevents.Behavior(new window.H.mapevents.MapEvents(newMap));

        // Create UI
        const createdUI = window.H.ui.UI.createDefault(newMap, defaultLayers);

        // Create a marker group for easy management
        const group = new window.H.map.Group();
        newMap.addObject(group);

        const initMapIcon = new window.H.map.Icon(mugIcon, { size: { w: 25, h: 25 } });
        const initMapCar = new window.H.map.Icon(carIcon, { size: { w: 25, h: 25 } });

        setMap(newMap);
        setMapIcon(initMapIcon);
        setMapCar(initMapCar);
        setPlatform(herePlatform);
        setMarkersGroup(group);
        setUI(createdUI);
        setIsMapReady(true);

        // Handle window resize
        window.addEventListener("resize", () => newMap.getViewPort().resize());
      } else {
        setTimeout(initMap, 500);
      }
    };

    return () => {
      // Cleanup
      document.head.removeChild(script);
      scriptsToLoad.forEach((url) => {
        const elements = url.endsWith(".css")
          ? document.querySelectorAll(`link[href="${url}"]`)
          : document.querySelectorAll(`script[src="${url}"]`);
        elements.forEach((el) => {
          if (el.parentNode) {
            el.parentNode.removeChild(el);
          }
        });
      });
      window.removeEventListener("resize", () => {});
    };
  }, []);

  // Add markers when the map is ready and locations change
  useEffect(() => {
    if (!ui || !isMapReady || !map || !platform || !markersGroup) return;

    // Clear existing markers
    markersGroup.removeAll();

    const processVenues = async () => {
      // Handle empty venues array
      if (venues.length === 0) {
        // Add default marker
        const marker = new window.H.map.Marker(defaultLocation);
        markersGroup.addObject(marker);
        map.setCenter(defaultLocation);
        return;
      }

      // Separate coordinates from addresses
      const addressesToGeocode = [];

      venues.forEach((venue, index) => {
        const title = `${venue.name}`;
        if (typeof venue === "object" && typeof venue.lat === "number" && typeof venue.lng === "number") {
          // Already has coordinates
          addMarker({ lat: venue.lat, lng: venue.lng }, title, venue);
        } else {
          // Plain address string
          addressesToGeocode.push({
            venue,
            address: `${venue.address}, ${venue.city} ${venue.state} ${venue.zipCode}`,
            title
          });
        }
      });

      // Batch geocode addresses (in groups of 10 for performance)
      const batchSize = 10;
      for (let i = 0; i < addressesToGeocode.length; i += batchSize) {
        const batch = addressesToGeocode.slice(i, i + batchSize);
        await batchGeocodeAddresses(batch);
      }

      // Adjust view to show all markers
      //   if (markersGroup.getObjects().length > 0) {
      //     // Get map's view bounds
      //     map.getViewModel().setLookAtData({
      //       bounds: markersGroup.getBoundingBox()
      //     });
      //   }
    };

    const batchGeocodeAddresses = async (addressBatch) => {
      // Use HERE's Batch Geocoder REST API instead of client-side geocoding
      const geocoder = platform.getSearchService();

      // Process addresses in parallel
      const promises = addressBatch.map((item) => {
        return new Promise((resolve, reject) => {
          geocoder.geocode(
            {
              q: item.address
            },
            (result) => {
              if (result.items && result.items.length > 0) {
                const position = result.items[0].position;
                const coordinates = { lat: position.lat, lng: position.lng };
                addMarker(coordinates, item.title, item.venue);
                updateVenue({
                  variables: {
                    changes: { ...coordinates, lastUpdate: new Date().toString() },
                    venueId: item.venue.venueId
                  }
                }).then(() => {
                  console.log(`lat and lng saved for ${item.venue.name} - ${item.venue.venueId}`);
                });
                resolve(coordinates);
              } else {
                console.warn(`No results found for: ${item.address}`);
                resolve(null);
              }
            },
            (error) => {
              console.error(`Geocoding error for ${item.address}:`, error);
              resolve(null); // Resolve with null instead of rejecting to continue with other addresses
            }
          );
        });
      });

      // Wait for all geocoding requests to complete
      return Promise.all(promises);
    };

    const addMarker = (coordinates, title, venue) => {
      // Create marker
      const marker = new window.H.map.Marker(coordinates, { data: { venue }, icon: mapIcon });

      // Add info bubble if title is provided
      if (title) {
        marker.setData(`<div style="padding: 8px;">${title}</div>`);
        console.log({ venue });
        // Add tap event to show info bubble
        marker.addEventListener("tap", (evt) => {
          history.push(`/member/venue/${venue.venueId}`);
          if (typeof onCloseMap === "function") {
            onCloseMap();
          }
        });
      }

      markersGroup.addObject(marker);
    };

    processVenues();
  }, [ui, isMapReady, map, platform, markersGroup, venues, mapIcon, history, onCloseMap, updateVenue]);

  const handleReset = useCallback(() => {
    map.setCenter(defaultLocation);
    map.setZoom(defaultZoom);
  }, [map]);

  const handleSetMyLocation = useCallback(
    (position) => {
      if (
        map &&
        typeof position?.coords?.latitude === "number" &&
        typeof position?.coords?.longitude === "number"
      ) {
        map.setCenter({ lat: position.coords.latitude, lng: position?.coords?.longitude });
      }
    },
    [map]
  );

  const handleGeolocationError = useCallback(() => {
    toast.error("Unable to find your location.", {
      position: "bottom-center",
      hideProgressBar: true,
      pauseOnHover: false,
      closeButton: false
    });
  }, []);

  const handleGetMyLocation = useCallback(() => {
    window.navigator.geolocation.getCurrentPosition(handleSetMyLocation, handleGeolocationError);
  }, [handleGeolocationError, handleSetMyLocation]);

  const handleUpdateMyLocation = useCallback(
    (position) => {
      if (
        map &&
        typeof position?.coords?.latitude === "number" &&
        typeof position?.coords?.longitude === "number"
      ) {
        const marker = new window.H.map.Marker(
          { lat: position.coords.latitude, lng: position.coords.longitude },
          { icon: mapCar }
        );
        map.addObject(marker);
      }
    },
    [map, mapCar]
  );

  const handleUpdateMyLocationError = useCallback(() => {
    console.error("Unable to update user location.");
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      window.navigator.geolocation.getCurrentPosition(handleUpdateMyLocation, handleUpdateMyLocationError);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [handleUpdateMyLocation, handleUpdateMyLocationError]);

  return (
    <Wrapper>
      <div
        ref={mapRef}
        style={{
          height: "100%",
          width: "100%",
          position: "relative",
          backgroundColor: "#e8eaed",
          borderRadius: "8px",
          overflow: "hidden"
        }}
      >
        <ButtonContainer>
          <Button onClick={handleReset}>Reset</Button>
          <Button onClick={handleGetMyLocation}>My Location</Button>
        </ButtonContainer>
      </div>
    </Wrapper>
  );
};

export default HereMap;
